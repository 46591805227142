<template>
  <!-- Settings tab at campaign level -->
  <div class="card min-height-card">
    <loader
      v-show="campaignInfo.status === 'loading'"
      class="fill--parent"
      :loading="campaignInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <!-- campaign name  -->

    <div class="u-spacing-pb-l">
      <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
        Campaign Name:
      </div>
      <div class="u-position-relative">
        <rb-input
          v-model="name"
          class="u-display-flex u-width-480px u-flex-align-items-center"
          @input="updateValue"
        />
        <div
          v-if="errorMessage.name"
          class="u-spacing-pv-s u-color-red-base u-font-size-7 u-spacing-mt-s"
        >
          {{ errorMessage.name }}
        </div>
      </div>
    </div>
    <!-- status -->
    <div class="u-spacing-pb-l">
      <div
        v-tippy="{ placement: 'top', arrow: true }"
        class="u-spacing-pb-s u-font-size-5 u-font-weight-600"
        :title="campaignStatusTooltip"
        style="width: fit-content"
      >
        Status:
      </div>
      <!-- :class="{'u-cursor-not-allowed1': disableStartCalander}" -->
      <rb-select
        class="filter--token u-max-width-160px"
        :width="'260px'"
        :send-details="true"
        :on-close="setSelectedStatus"
        :options="statusValues"
        :class="{ 'u-cursor-not-allowed1': disableCampaignStataus }"
      >
        <div
          slot="trigger"
          class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
          :class="{ 'u-cursor-not-allowed1': disableCampaignStataus }"
        >
          <span class="u-font-size-6">
            {{ (selectedStatus && selectedStatus.title) || 'NA' }}
          </span>
          <rb-icon
            class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
      </rb-select>
    </div>
    <!-- start and end dates -->
    <div class="u-spacing-pb-l u-display-flex">
      <div class="u-spacing-pr-l u-flex-direction-column u-color-grey-lighter">
        <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
          Start Date:
        </div>
        <div v-click-outside="hideStartCalendar">
          <span
            class="filter--token u-font-size-6 u-spacing-p-s u-display-inline-flex u-flex-align-items-center"
            :class="{
              'u-cursor-not-allowed': disableStartCalander,
              'u-cursor-pointer': !disableStartCalander
            }"
            @click="openCalendar('startDate')"
          >
            <rb-icon
              icon="calendar"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-spacing-ph-xs u-line-height-1_2">
              {{ dateFormatFilter(campaignDates.start, 'start') }}</span
            >
            <span> PST</span>
          </span>
          <datepicker
            ref="startDate"
            v-model="campaignDates.start"
            style="right: -200px"
            name="startDate"
            :disabled-dates="disableStartDates"
            :disabled="disableStartCalander"
          />
        </div>
      </div>

      <div class="u-flex-direction-column">
        <div class="u-spacing-pb-s u-font-size-5">
          <span class="u-font-weight-600">End Date:</span>
          <span class="u-color-grey-lighter">Optional</span>
        </div>
        <div
          v-click-outside="hideEndCalendar"
          class="entity-details-settings-calendar"
        >
          <span
            class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('endDate')"
          >
            <rb-icon
              icon="calendar"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-spacing-ph-xs u-line-height-1_2">
              {{ dateFormatFilter(campaignDates.end, 'end') }}</span
            >
            <span
              v-if="
                dateFormatFilter(campaignDates.end, 'end') !== 'No end date'
              "
            >
              PST</span
            >
          </span>
          <datepicker
            ref="endDate"
            v-model="campaignDates.end"
            style="right: -100px"
            name="endDate"
            :disabled-dates="disableEndDates"
            @input="onEndDateSelect"
          >
            <div
              slot="beforeCalendarHeader"
              class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
              :class="noEndDate && 'u-color-white-base u-bg-color-orange-base'"
              data-cy="noEndDate"
              @click="selectNoEndDate"
            >
              No end date
            </div>
          </datepicker>
        </div>
        <div
          v-if="errorMessage.endDate"
          class="u-spacing-pv-s u-color-red-base u-font-size-7"
        >
          {{ errorMessage.endDate }}
        </div>
      </div>
    </div>

    <!-- Advertiser -->
    <div
      v-if="advertiser"
      class="u-spacing-pb-l"
    >
      <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
        Advertiser:
      </div>
      <div class="u-position-relative u-display-flex">
        <rb-input
          v-model="advertiserName"
          :disabled="true"
          class="u-display-flex u-width-600px u-flex-align-items-center"
        />
      </div>
    </div>

    <div
      v-if="campaignMetdataChanged.length"
      class="u-spacing-pt-l"
    >
      <footerButtons
        :button-min-width="'u-min-width-100px'"
        :disable-left-btn="applyButtonDisabled"
        :left-button-type="applyButtonDisabled ? 'filled' : 'hollow'"
        @onClickBtnRight="onCancel"
        @onClickBtnLeft="onSave"
      />
    </div>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import loader from '@/components/basic/loader';
import campaignTags from '@/pages/entity_details/widget_templates/settings/campaignTags.vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';

export default {
  components: {
    footerButtons,
    Datepicker,
    loader,
    campaignTags
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    entityConfig: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      getState: null,
      initialSetPortfolio: null,
      noEndDate: true,
      updateMetadata: null,
      name: null,
      portfolioData: [],
      nonePortfolio: { portfolio_id: null, portfolio_name: 'None' },
      loadPortfolio: false,
      disableStartDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      disableEndDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      selectedPortfolio: null,
      campaignDates: {
        start: new Date(),
        end: new Date()
      },
      requestPayload: null,
      settingsRequestMap: {
        state: {
          status: ':state',
          view_old_state: ':oldState',
          state: ':state'
        },
        portfolio: {
          portfolio_id: ':portfolio',
          view_old_portfolio: ':oldPortfolioName',
          view_portfolio: ':portfolioName'
        },
        name: {
          name: ':name',
          view_old_name: ':oldName'
        },
        startDate: {
          new_start_date: ':startDate',
          view_old_start_date: ':oldStartDate',
          view_start_date: ':startDateView'
        },
        endDate: {
          new_end_date: ':endDate',
          view_old_end_date: ':oldEndDate',
          view_end_date: ':endDateView'
        }
      },
      statusValues: [
        {
          title: 'Enabled',
          value: 'enabled'
        },
        {
          title: 'Paused',
          value: 'paused'
        },
        {
          title: 'Archived',
          value: 'archived'
        }
      ],
      selectedStatus: null,
      tabConfig: null,
      advertiserName: 'NA',
      portfolioVal: true,
      campaignList: true,
      advertiser: false,
      errorMessage: {},
      disableStartCalanderCriteria: ['live', 'paused'],
      campaignStatusTooltip: ''
    };
  },
  computed: {
    campaignMetdataChanged() {
      const selectedStatus = this.statusValues.filter(
        (item) => item.title === this.campaignInfo?.data?.state
      );
      const itemsChanged = [];
      if (this.name !== this.campaignInfo?.data?.name) {
        itemsChanged.push({
          value: 'name',
          ':name': this.name?.trim(),
          ':oldName': this.campaignInfo?.data?.name
        });
      }
      if (selectedStatus.length && this.selectedStatus !== selectedStatus[0]) {
        itemsChanged.push({
          value: 'state',
          ':state': this.selectedStatus?.value,
          ':oldState': this.campaignInfo?.data?.state
        });
      }
      if (
        moment(this.campaignDates.start).format('MMM DD, YYYY') !==
        moment(this.campaignInfo?.data?.startDate).format('MMM DD, YYYY')
      ) {
        itemsChanged.push({
          value: 'startDate',
          ':startDate': moment(this.campaignDates.start).format('MM/DD/YYYY'),
          ':oldStartDate': this.campaignInfo?.data?.startDate,
          ':startDateView':
            this.campaignDates.start &&
            moment(this.campaignDates.start).format('MMM DD, YYYY')
        });
      }
      if (
        !(
          new Date(this.campaignInfo?.data?.endDate).getFullYear() === 9999 &&
          this.campaignDates.end === null
        )
      ) {
        // eslint-disable-next-line
        if (
          moment(this.campaignDates.end).format('MMM DD, YYYY') !==
          moment(this.campaignInfo?.data?.endDate).format('MMM DD, YYYY')
        ) {
          itemsChanged.push({
            value: 'endDate',
            ':endDate':
              this.campaignDates.end === null
                ? moment().year(9999).format('MM/DD/YYYY')
                : (this.campaignDates.end &&
                    moment(this.campaignDates.end).format('MM/DD/YYYY')) ||
                  null,
            ':oldEndDate': this.campaignInfo?.data?.endDate,
            ':endDateView':
              this.campaignDates.end === null
                ? 'No end date'
                : (this.campaignDates.end &&
                    moment(this.campaignDates.end).format('MMM DD, YYYY')) ||
                  'No end date'
          });
        }
      }
      // eslint-disable-next-line
      if (
        this.selectedPortfolio?.portfolio_id !== null &&
        this.selectedPortfolio?.portfolio_id !==
          this.campaignInfo?.data?.portfolioId
      ) {
        itemsChanged.push({
          value: 'portfolio',
          ':portfolio': this.selectedPortfolio?.portfolio_id,
          ':portfolioName': this.selectedPortfolio?.portfolio_name,
          ':oldPortfolioName':
            this.initialSetPortfolio?.portfolio_name || 'None'
        });
      }
      return itemsChanged;
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    advertiserInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getAdvertiser];
    },
    applyButtonDisabled() {
      const errorMessage = {};
      if (this.selectedStatus?.title === 'Extend') {
        if (
          new Date(this.campaignInfo?.data?.endDate).getFullYear() === 9999 &&
          this.campaignDates.end === null
        ) {
          errorMessage.endDate =
            'Please change the end date to extend the campaign.';
        } else {
          if (
            moment(this.campaignDates.end).format('MMM DD, YYYY') ===
            moment(this.campaignInfo?.data?.endDate).format('MMM DD, YYYY')
          ) {
            errorMessage.endDate =
              'Please change the end date to extend the campaign.';
          } else {
            errorMessage.endDate = '';
          }
        }
      }
      if (this.name?.length === 0) {
        errorMessage.name = 'Campaign Name is required';
      } else if (this.name?.length > 255) {
        errorMessage.name =
          'Please enter the campaign name within 255 characters.';
      } else {
        errorMessage.name = null;
      }
      this.errorMessage = errorMessage;
      return Object.values(errorMessage).some(Boolean);
    },
    disableStartCalander() {
      // return this.disableStartCalanderCriteria.includes(this.selectedStatus?.title.toLowerCase());
      return typeof this.selectedStatus === 'object'
        ? this.disableStartCalanderCriteria.includes(
            this.selectedStatus?.title.toLowerCase()
          )
        : this.disableStartCalanderCriteria.includes(
            this.selectedStatus[0]?.title.toLowerCase()
          );
    },
    disableCampaignStataus() {
      return this.campaignInfo?.data.state === 'Proposal'
        ? ((this.campaignStatusTooltip =
            'Please wait for the review to complete. It takes 1-3 days typically since the creation of the campaign.'),
          true)
        : this.campaignInfo?.data.state === 'Scheduled'
        ? ((this.campaignStatusTooltip =
            'Campaign is scheduled to go live at 12am PST on start date'),
          false)
        : this.campaignInfo?.data.state === 'Rescheduled'
        ? ((this.campaignStatusTooltip =
            'Campaign is scheduled to go live at 12am PST on start date'),
          false)
        : this.campaignInfo?.data.state === 'Live'
        ? ((this.campaignStatusTooltip =
            'Campaign is live and serving the SB ads.'),
          false)
        : this.campaignInfo?.data.state === 'Paused' &&
          this.campaignInfo?.data?.reviewStatus === 'complete' &&
          !this.campaignInfo?.data?.reviewComments?.length
        ? ((this.campaignStatusTooltip =
            'Campaign is paused. Please enable the campaign to start serving.'),
          false) // review passed
        : this.campaignInfo?.data.state === 'Completed'
        ? ((this.campaignStatusTooltip =
            'Campaign is completed for the current period.'),
          false)
        : this.campaignInfo?.data.state === 'Enabled'
        ? ((this.campaignStatusTooltip =
            'Campaign is enabled and will be scheduled.'),
          true)
        : this.campaignInfo?.data.state === 'Extend'
        ? ((this.campaignStatusTooltip =
            'Campaign is extended and will be rescheduled.'),
          true)
        : this.campaignInfo?.data.state === 'Paused' &&
          this.campaignInfo?.data?.reviewComments?.length
        ? ((this.campaignStatusTooltip =
            'Please updated the failed components submit for review to enable the campaign.'),
          true)
        : false;
    }
  },
  watch: {
    campaignInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.errorMessage = {};
          this.setCampaignDetails();
          this.setCamapignStatusOptions(
            this.tabConfig?.apiConfigs?.statusValues
          );
        }
      },
      immediate: true
    },
    advertiserInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.setAdvertiserDetails();
        }
      },
      immediate: true
    },
    name() {
      this.errorMessage.name = '';
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    // this.statusValues = tabConfig?.apiConfigs?.statusValues;
    if (
      tabConfig?.apiConfigs?.portfolio_campaignList !== undefined ||
      tabConfig?.apiConfigs?.portfolio_campaignList === false
    ) {
      this.portfolioVal = false;
      this.campaignList = false;
    } else {
      this.portfolioVal = true;
      this.campaignList = true;
    }
    if (
      tabConfig?.apiConfigs?.showAdvertiser !== undefined ||
      tabConfig?.apiConfigs?.showAdvertiser === true
    ) {
      this.advertiser = true;
    } else {
      this.advertiser = false;
    }
    this.disableStartDates.from = new Date(this.campaignInfo?.data?.endDate);
    this.getAdvertiser();
    // this.campaignInfo.data.state = 'Completed';
    this.setCamapignStatusOptions(tabConfig?.apiConfigs?.statusValues);
  },
  methods: {
    setCamapignStatusOptions(options) {
      this.statusValues = [];
      this.statusValues = options.filter((item) => {
        if (
          this.campaignInfo?.data.state === 'Proposal' &&
          item.title === 'Proposal'
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Scheduled' &&
          (item.title === 'Paused' || item.title === 'Scheduled')
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Rescheduled' &&
          (item.title === 'Paused' || item.title === 'Rescheduled')
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Live' &&
          (item.title === 'Live' ||
            item.title === 'Paused' ||
            item.title === 'Completed')
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Paused' &&
          (['pending', 'cancelled'].includes(
            this.campaignInfo?.data?.reviewStatus
          ) ||
            this.campaignInfo?.data?.reviewComments?.length) &&
          item.title === 'Paused'
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Paused' &&
          (this.campaignInfo?.data?.campaignType === 'Sponsored Product'
            ? true
            : this.campaignInfo?.data?.reviewStatus === 'complete' &&
              !this.campaignInfo?.data?.reviewComments?.length) &&
          (item.title === 'Paused' ||
            item.title === 'Enabled' ||
            item.title === 'Completed')
        ) {
          return item;
        } // review passed
        if (
          this.campaignInfo?.data.state === 'Completed' &&
          (item.title === 'Completed' || item.title === 'Extend')
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Enabled' &&
          item.title === 'Enabled'
        ) {
          return item;
        }
        if (
          this.campaignInfo?.data.state === 'Extend' &&
          item.title === 'Extend'
        ) {
          return item;
        }
      });
      this.selectedStatus =
        (this.statusValues.length && this.statusValues[0]) || null;
    },
    onCancel() {
      this.setCampaignDetails();
    },
    onSave() {
      // todo update payload for API
      const campagnMetadataUdpated = this.campaignMetdataChanged;
      let [campaignMetdataStateChanged, campaignMetdataOtherChanged] = [[], []];
      const stateObject = this.campaignMetdataChanged.find((obj) => {
        return obj.value === 'state';
      });
      if (
        this.entityConfig.entityType === 'SB' &&
        this.campaignMetdataChanged.length > 1 &&
        typeof stateObject === 'object'
      ) {
        campaignMetdataStateChanged.push(stateObject);
        campaignMetdataOtherChanged = campagnMetadataUdpated.filter((obj) => {
          return obj.value !== 'state';
        });
        this.dispatchAction(campaignMetdataStateChanged);
        this.dispatchAction(campaignMetdataOtherChanged);
        return;
      }
      this.dispatchAction(this.campaignMetdataChanged);
    },
    dateFormatFilter: function (value, type) {
      if (!value || value === null) return `No ${type} date`;
      return moment(value).format('MMM DD, YYYY');
    },
    setSelectedStatus(context, selection) {
      if (!selection.length) return;
      this.selectedStatus = selection[0];
    },

    updateValue(input) {},
    setCampaignDetails() {
      this.name = this.campaignInfo?.data?.name || null;
      this.campaignDates.start = this.campaignInfo?.data?.startDate;
      this.campaignDates.end =
        new Date(this.campaignInfo?.data?.endDate).getFullYear() === 9999
          ? null
          : this.campaignInfo?.data?.endDate;
      this.noEndDate = !this.campaignDates.end;
      this.selectedPortfolio = this.initialSetPortfolio;
      this.selectedStatus =
        (this.statusValues.length && this.statusValues[0]) || null;
    },
    setAdvertiserDetails() {
      this.advertiserName = this.advertiserInfo?.data?.[0]?.advertiser_name;
    },
    openCalendar(param) {
      if (param === 'endDate') {
        this.noEndDate = !this.campaignDates.end;
      }
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    hideStartCalendar() {
      this.$refs?.startDate?.close();
    },
    hideEndCalendar() {
      this.noEndDate = !this.campaignDates.end;
      this.$refs?.endDate?.close();
    },
    selectNoEndDate() {
      this.campaignDates.end = null;
      this.hideEndCalendar();
    },
    dispatchAction(campaignMetdataChanged) {
      const payload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':entityId': this.entityId,
        ':pageUrl': window.location.href,
        ':advertiserId': this.$route.query?.advertiser_id
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      const viewPayload = {};
      const actionPayload = {};
      for (const campaignMetdata of campaignMetdataChanged) {
        const itemReq = this.settingsRequestMap[campaignMetdata.value];
        for (const key in itemReq) {
          viewPayload[key] = campaignMetdata[itemReq[key]];
          if (!key.includes('view_')) {
            actionPayload[key] = campaignMetdata[itemReq[key]];
          }
        }
      }

      payload.actionPayload = { ...payload.actionPayload, ...actionPayload };
      payload.viewPayload = { ...payload.viewPayload, ...viewPayload };
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: [payload],
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        entityName: {
          singular: 'Campaign Settings',
          plural: 'Campaign Settings',
          noCount: true
        },
        routeData: this.$route
      });
    },
    getAdvertiser() {
      if (this.tabConfig.apiConfigs?.fetchAdvertiser) {
        this.$store.dispatch(this.tabConfig.apiConfigs.fetchAdvertiser, {
          requestParams: this.tabConfig.getAdvertiserRequestPayload(
            this.$route?.query?.advertiser_id
          )
        });
      }
    },
    onEndDateSelect(date) {
      if (date) this.disableStartDates.from = new Date(this.campaignDates.end);
    }
  }
};
</script>
<style lang="css">
.entity-details-settings-calendar .vdp-datepicker__calendar {
  padding: 0px;
}
.entity-details-settings-calendar .vdp-datepicker__calendar div {
  padding: 16px;
}
</style>
<style scoped lang="css">
.filter--token {
  border-radius: 2px;
}
.min-height-card {
  padding: 2.4rem;
  padding-bottom: 4.8rem;
}

.no-end-date:hover,
.no-end-date:focus {
  background: #ffa800;
}
.u-cursor-not-allowed {
  cursor: not-allowed !important;
}
.u-cursor-not-allowed1 {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
</style>
